import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import Home from 'components/Home'

const routes = [{
    path: '/',
    component: Home

}
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior
})

const scrollBehavior = function (to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    return { x: 0, y: 0 }
}

export default router