<template>
  <div id="home">
    <div class="main-content" ref="mainContent">
      <div class="content1">
        <div class="words" ref="words">{{ mainText }}</div>
        <div class="days" ref="days">
          <span class="days-number" ref="daysNumber">{{ daysNumber }}</span>
          <span class="days-label" ref="daysLabel">days</span>
        </div>
        <div class="seconds" ref="seconds">{{ seconds }}</div>
      </div>
    </div>
    <div class="blog">
      <a :href="weibo" target="_blank">@小清</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      screenWidth: "",
      screenHeight: "",
      mainText: "爱你呀,清",
      begin: "2020-7-26 20:00:00",
      daysNumber: "",
      seconds: "",
      weibo: "https://weibo.com/u/3975924440",
    };
  },
  created() {
    this.timeHandler();
    document.title = "我的爱如潮水...";
  },
  mounted() {
    // 启动计时器
    window.setInterval(() => {
      this.timeHandler();
    }, 1000);
    this.resizeHandler();
    // 窗口大小变化
    this.resizeWindow();
  },
  methods: {
    resizeWindow() {
      // 窗口变化
      window.onresize = () => {
        return (() => {
          this.resizeHandler();
        })();
      };
    },
    resizeHandler() {
      this.screenWidth = document.body.clientWidth;
      this.screenHeight = document.documentElement.clientHeight;
      this.$refs.mainContent.style.height = this.screenHeight + "px";
      if (this.screenWidth > 1280) {
        this.$refs.mainContent.style &&
          (this.$refs.mainContent.style.width = "1280px");
      } else {
        this.$refs.mainContent.style &&
          (this.$refs.mainContent.style.width = this.screenWidth + "px");
      }
      let width = this.screenWidth * 0.9;
      let daysSize = this.screenWidth * 0.8;
      let time = Math.floor(this.screenWidth / 20);
      //   计算字体大小
      let textSize = Math.floor(width / this.$refs.words.innerText.length);
      let daysTime = Math.floor(
        (daysSize * 0.8) / this.$refs.daysNumber.innerText.length
      );
      let daysText = Math.floor(
        (daysSize * 0.2) / this.$refs.daysLabel.innerText.length
      );
      //   字体默认最大值
      textSize = textSize < 150 ? textSize : 150;
      daysTime = daysTime < 216 ? daysTime : 216;
      daysText = daysText < 54 ? daysText : 54;
      time = time < 32 ? time : 32;
      //   设置字体大小
      this.$refs.words.style.fontSize = textSize + "px";
      this.$refs.days.style.fontSize = daysTime + "px";
      this.$refs.daysLabel.style.fontSize = daysText + "px";
      this.$refs.seconds.style.fontSize = time + "px";
    },
    timeHandler() {
      let format = this.begin.replace(/-/g, "/");
      let startTime = Date.parse(format);
      let duration = parseInt(Date.now() - startTime) / 1000;

      let seconds = parseInt(duration % 60);
      if (seconds < 10) seconds = "0" + seconds;
      duration = parseInt(duration / 60);
      let minutes = duration % 60;
      if (minutes < 10) minutes = "0" + minutes;
      duration = parseInt(duration / 60);
      let hours = duration % 24;
      if (hours < 10) hours = "0" + hours;
      duration = parseInt(duration / 24);
      let days = duration;
      this.daysNumber = days;
      this.seconds =
        hours + " hours " + minutes + " minutes " + seconds + " seconds";
    },
  },
};
</script>

<style scoped>
.main-content {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}
.content1 {
  display: block;
}
.words {
  font-size: 90px;
  font-family: "Regular", cursive;
  width: auto;
  text-align: center;
}
.days {
  font-size: 216px;
  font-family: "Rye", cursive;
  width: auto;
  text-align: center;
}

.days-label {
  font-size: 54px;
}

.seconds {
  margin-top: 32px;
  font-size: 32px;
  font-family: "Monda_bo", Georgia, sans-serif;
  width: auto;
  text-align: center;
}

.seconds-label {
  margin-top: 32px;
  font-size: 32px;
  font-family: "Monda_no", Georgia, sans-serif;
  width: auto;
  text-align: center;
}
.blog {
  /* width: 100%; */
  position: fixed;
  right: 30px;
  bottom: 20px;
}

@font-face {
  font-family: "Regular";
  font-style: normal;
  font-weight: 400;
  src: local("Rye Regular"), local("Rye-Regular"),
    url("~assets/font/kangkang.ttf") format("woff");
}

@font-face {
  font-family: "Rye";
  font-style: normal;
  font-weight: 400;
  src: local("Rye Regular"), local("Rye-Regular"),
    url("~assets/font/Clockopia.ttf") format("woff");
}
@font-face {
  font-family: "Monda_no";
  font-style: normal;
  font-weight: normal;
  src: local("Monda"), local("Monda-Regular"),
    url("~assets/font/Monda-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Monda_bo";
  font-style: normal;
  font-weight: bold;
  src: local("Monda Bold"), local("Monda-Bold"),
    url("~assets/font/Monda-Bold.ttf") format("truetype");
}
</style>